import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {path: '', redirectTo: '/app/about', pathMatch: 'full'},
  {
    path: 'account',
    loadChildren: () => import('account/account.module').then(m => m.AccountModule), // Lazy load account module
    data: {preload: true}
  },
  {
    path: 'app',
    loadChildren: () => import('app/app.module').then(m => m.AppModule), // Lazy load account module
    data: {preload: true}
  },
  {
    path: 'calendar',
    loadChildren: () => import('./external-calendar/external-calendar.module').then(m => m.ExternalCalendarModule),
    data: {preload: true}
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule],
  providers: []
})
export class RootRoutingModule {
}
